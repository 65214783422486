import React from "react";
import {navigate} from "gatsby";

class Guide extends React.Component {
	
	componentDidMount() {
		navigate(`/guide/feature`);
	}

	render() {
		return (
			<div></div>
		);
	}
}

export default Guide;